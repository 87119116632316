import Seo from 'src/components/seo/Seo'

function AppDownload() {
  return <Seo title="Aplicativo | Decathlon" />
}

export const getServerData = () => {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`

  return {
    status: 301,
    props: {},
    headers: {
      'cache-control': ONE_YEAR_CACHE,
      location: `/clube/?utm_source=clube&utm_medium=promocao`,
    },
  }
}

export default AppDownload
